
import Lottie from 'react-lottie';
import animation from '../animations/faceConfirmed.json';

const PreLoad = props => {

    


    
    return(
        <>
                <div style={{backgroundColor:'#000', width:'100%', height:'100vh', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}
                >
                    <Lottie
                                        options={{
                                            loop: false,
                                            autoplay: true,
                                            animationData: animation,
                                            rendererSettings: {
                                                preserveAspectRatio: 'xMidYMid slice'
                                            }
                                        }}
                                        height={'200px'}
                                        width={'200px'}

                     />
                </div>


        </>
    )
}
export default PreLoad