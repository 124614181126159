import QrScanner from "qr-scanner";
import React, { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie";
import animationDataQR from "../animations/qr.json";
import "./style.css";

function QrCodeReader(props) {
  // QR States
  const scanner = useRef();
  const videoEl = useRef(null);
  const qrBoxEl = useRef(null);
  const [qrOn, setQrOn] = useState(true);

  const onScanFail = (err) => {
    console.log(err);
  };

  useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      scanner.current = new QrScanner(videoEl?.current, props.onScanSuccess, {
        onDecodeError: onScanFail,
        preferredCamera: "environment",
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: undefined,
      });

      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }

    return () => {
      if (!videoEl?.current) {
        scanner?.current?.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (!qrOn)
      console.error(
        "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
      );
  }, [qrOn]);

  return (
    <div
      style={{
        width: "300px",
        height: "300px",
        marginTop: 10,
        overflow: "hidden",
      }}
      className="qr-reader"
    >
      <video ref={videoEl}></video>
    </div>
  );
}

export default QrCodeReader;
