import { useState } from "react";
import { Controller } from "react-hook-form";
import {
  TextField,
  Switch,
  Button,
  FormControlLabel,
  Container,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Avatar,
  FormHelperText,
  Typography,
  CircularProgress,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { useCondominio } from "../contexts/condominio-context";
import { usePreRegisterForm } from "../contexts/pre-register-form-context";
import httpClient from "../../../http-client";
import { useUnitSelected } from "../contexts/unit-selected-context";
import InputCpf from "./form/input-cpf";
import InputCellPhone from "./form/input-cellphone";
import FailedFormSubmitModal from "./failed-form-submit-modal";
import {
  extractBase64fromString,
  extractOnlyNumbersFromString,
} from "../../../utils/utils";
import { useSteps } from "../contexts/steps-context";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";

function PreRegisterForm() {
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const { nomeDoCondominio } = useCondominio();
  const { nextStep } = useSteps();
  const { unitSelected } = useUnitSelected();
  const {
    handleSubmit,
    watch,
    control,
    formState: { isSubmitting },
  } = usePreRegisterForm();
  const facePicture = watch("facePicture");
  const enterWithVehicle = watch("enterWithVehicle");

  const onCloseErrorModal = () => {
    setIsOpenErrorModal(false);
  };

  const onSubmit = async (values) => {
    try {
      const data = {
        id_unidade: unitSelected.id,
        ficha: JSON.stringify({
          base64_photo: extractBase64fromString(values.facePicture),
          name: values.fullName,
          cpf: values.cpf,
          document: values.documentNumber,
          cell_number:
            values.phoneNumber &&
            extractOnlyNumbersFromString(values.phoneNumber),
          email: values.email,
          birthdate: values.birthdate,
          type: values.userType,
          observation: values.observation,
          vehicle: values.enterWithVehicle
            ? {
                vehicle_type: values.vehicle.vehicleType
                  ? String(values.vehicle.vehicleType)
                  : "0",
                vehicle_brand: values.vehicle.brand,
                model: values.vehicle.model,
                license_plate: values.vehicle.plate,
                color: values.vehicle.color,
              }
            : undefined,
        }),
      };

      const response = await httpClient.post("/register", data);

      if (response.data.status) {
        return nextStep();
      }

      throw new Error("Failed to submit form");
    } catch (error) {
      console.log("failed to submit form", error);
      setIsOpenErrorModal(true);
    }
  };

  return (
    <Container sx={{ paddingBottom: 6 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Avatar
                alt="Avatar"
                src={facePicture}
                sx={{ width: 120, height: 120, border: "2px solid #e9e9e980" }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="fullName"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  ref={undefined}
                  label="Nome completo"
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="cpf"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <InputCpf
                  {...field}
                  ref={undefined}
                  label="CPF"
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="documentNumber"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  ref={undefined}
                  label="Documento"
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <InputCellPhone
                  {...field}
                  ref={undefined}
                  label="Celular"
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="email"
                  ref={undefined}
                  label="E-mail"
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-br"
            >
              <Controller
                name="birthdate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    label="Data de Nascimento"
                    onChange={(date) => field.onChange(date)}
                    value={field.value}
                    sx={{ width: "100%" }}
                    localeText={{ toolbarTitle: "Selecione uma data" }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          {/* Campo Tipo do usuário temporariamente desativado */}
          {/* <Grid item xs={12}>
            <FormControl fullWidth>
              <Controller
                name="userType"
                control={control}
                defaultValue="locador"
                render={({ field }) => (
                  <>
                    <InputLabel id="user-type-select-label">Tipo</InputLabel>
                    <Select
                      {...field}
                      labelId="user-type-select-label"
                      id="user-type-select"
                      label="Tipo"
                    >
                      <MenuItem value={'locador'}>Locador</MenuItem>
                      <MenuItem value={'locatario'}>Locatário</MenuItem>
                    </Select>
                  </>
                )}
              />
            </FormControl>
          </Grid> */}

          <Grid item xs={12}>
            <Controller
              name="observation"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  ref={undefined}
                  multiline
                  rows={3}
                  label="Observação"
                  fullWidth
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="enterWithVehicle"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Switch {...field} />}
                  label="Possui veículo?"
                />
              )}
            />
          </Grid>

          {enterWithVehicle ? (
            <>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Tipo
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue="0"
                  >
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="Carro"
                    />
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Moto"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="vehicle.brand"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      ref={undefined}
                      label="Marca"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="vehicle.model"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      ref={undefined}
                      label="Modelo"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="vehicle.plate"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      ref={undefined}
                      label="Placa"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="vehicle.color"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      ref={undefined}
                      label="Cor"
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
            </>
          ) : null}

          <Grid item xs={12}>
            <Controller
              name="agreeToTerms"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <FormControlLabel
                    control={<Switch {...field} />}
                    label={
                      <Typography variant="body2">
                        Tenho ciência que os dados pessoais acima serão tratados
                        para finalidade específica de acesso ao{" "}
                        {nomeDoCondominio}.
                      </Typography>
                    }
                  />
                  <FormHelperText>
                    Mais esclarecimentos em nossas páginas de{` `}
                    <a
                      href="https://tecnorise.com/politica-de-privacidade"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Política de privacidade
                    </a>
                    {` e `}
                    <a
                      href="https://tecnorise.com/termo-de-uso"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Termo de uso
                    </a>
                  </FormHelperText>
                  {error ? (
                    <FormHelperText error>{error.message}</FormHelperText>
                  ) : null}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Grid container justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                disabled={isSubmitting}
                endIcon={
                  isSubmitting ? (
                    <CircularProgress
                      size={20}
                      sx={{ color: "rgba(0, 0, 0, 0.26)" }}
                    />
                  ) : undefined
                }
                sx={{
                  minWidth: 250,
                }}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <FailedFormSubmitModal
        isOpen={isOpenErrorModal}
        onClose={onCloseErrorModal}
      />
    </Container>
  );
}

export default PreRegisterForm;
