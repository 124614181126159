import './Input.css'

function Input({ text, type, placeholder, value, onChange, disabled, required, erro, erroText }) {
    return (
        <div className="input-data">
            <label>{text}</label>
            <input type={type} value={value} onChange={onChange} disabled={disabled} />

            {erro ?
                <div
                    style={{
                        color: 'red',
                        fontSize: 12,
                        marginTop: 6,
                    }}
                >
                    {erroText}
                </div>
                : required ?
                    <div
                        style={{
                            marginTop: 6,
                            fontSize: 12,
                            color: 'lightgray',
                        }}

                    >
                        Campo obrigatório
                    </div>
                    : null
            }

        </div>
    )
}

export default Input