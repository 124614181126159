import { TextField } from "@mui/material";
import { useIMask } from 'react-imask';

const InputCpf = (props) => {
  const { onChange, ...other } = props;
  const { ref, value } = useIMask({
    mask: '000.000.000-00',
    lazy: true,
    definitions: {
      '#': /[1-9]/,
    },
  })

  return (
    <TextField
      {...other}
      inputRef={ref}
      inputProps={{
        inputMode: 'numeric',
        value,
        onChange,
      }}
    />
  );
}

export default InputCpf
