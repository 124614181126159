import React, { useState, useRef } from 'react';
import Webcam from 'react-webcam';
import { useFaceDetection } from 'react-use-face-detection';
import FaceDetection from '@mediapipe/face_detection';
import { Camera } from '@mediapipe/camera_utils';
// import { AiOutlineClose, AiTwotoneWallet } from 'react-icons/ai'
// import axios from 'axios';
import { useEffect } from 'react';
import Lottie from 'react-lottie';
import animation from '../../../components/animations/loadFace.json';
import { Button } from "@mui/material";

import { useSteps } from "../contexts/steps-context";
import { usePreRegisterForm } from "../contexts/pre-register-form-context";

//https://stackblitz.com/edit/js-4tzynd?file=index.js 
const buttonStyles = {
  position: 'absolute',
  bottom: 15,
  right: 'calc(50% - 125px)',
  width: 250,
  transition: 'all 0.5s ease',
  ':disabled': {
    opacity: 0.4,
    backgroundColor: 'primary.main',
    color: 'white',
  }
}

const videoConstraints = {
  width: 480,
  height: 480,
  facesMode: "user",
  frameRate: 10
};

const { innerWidth, innerHeight } = window;

const svgIcon = (status) => (
  <>
    <svg
      className="svg"
      viewBox="0 0 210 300"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <mask id="overlay-mask" x="0" y="0" width="100%" height="100%">
          <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
          {/* <circle cx="50%" cy="50%" r="80" w="100" /> */}
          <circle cx="50%" cy="50%" r="80" w="100" />

          {/* <path
            d="M149.8,399.69h-10.46c-75.39,0-136.5-61.11-136.5-136.5V118.97c0-64.14,52-116.13,116.13-116.13h51.2
            c64.14,0,116.13,52,116.13,116.13v144.22C286.3,338.57,225.19,399.69,149.8,399.69z"
            fill="#000"
            x="0"
            y="0"
            w="100"
            transform="scale(0.5)"
        /> */}

        </mask>
      </defs>
      <rect x="0" y="0" width="100%" height="100%" mask="url(#overlay-mask)" />
      <g style={{
        stroke: '#28A745',
        strokeWidth: status == false ? 0 : 2,
      }} fill="none">
        <circle cx="50%" cy="50%" r="80" w="100" />
      </g>
    </svg>

  </>
);

{/* <circle cx="50%" cy="50%" r="80" w="100" stroke="#28A745" stroke-width="2" fill="#000"  /> */ }


const WebcamDemo = ({ finalizarFoto }) => {

  const [erroText, setErroText] = useState({ menssagem: 'Nenhum rosto detectado', seta: null });
  const [reconehcimentoFacial, setReconehcimentoFacial] = useState(false);
  const [webCamVisivel, setWebCamVisivel] = useState(true);


  const timeFace = useRef(true)
  const timePositivoFace = useRef(0)
  const cam = useRef()

  const { nextStep } = useSteps();
  const { setValue } = usePreRegisterForm();

  const buttonClick = useRef(true)
  const { webcamRef, boundingBox, isLoading, detected, facesDetected } = useFaceDetection({
    faceDetectionOptions: {
      model: 'short'
    },
    faceDetection: new FaceDetection.FaceDetection({
      locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
    }),


    camera: ({ mediaSrc, onFrame, width, height }) => {

      return new Camera(mediaSrc, {
        onFrame,
        width: 480,
        height: 480
      })

    }

  });


  const wait = ms => (
    new Promise(resolve => {
      setTimeout(() => resolve(), ms)
    })
  )




  const tirarFoto = async (facesDetected) => {
    if (buttonClick.current) {
      buttonClick.current = false
      try {

        const imageSrc = webcamRef.current.getScreenshot();

        setValue('facePicture', imageSrc);
        nextStep();

        // const foto = webcamRef.current.getScreenshot();
        // setReconehcimentoFacial(foto)
        // finalizarFoto({
        //   fotoBase64: foto
        // });


      } catch (e) {
        //console.log(e)
        await wait(1000)
        setErroText({ menssagem: 'Erro ao identificar a foto, tente novamente.', seta: null });
        //setReconehcimentoFacial(null)
        buttonClick.current = true
        timeFace.current = true
        timePositivoFace.current = 0
      } finally {

      }
    }
  };

  useEffect(() => {
    // if (timePositivoFace.current == 5) {
    //   timePositivoFace.current = 0
    //   if (buttonClick.current = true) {
    //     tirarFoto()
    //     timeFace.current = false
    //     buttonClick.current = false
    //   }
    //   // timeFace.current = false
    // }
    if (timeFace.current) {
      // timeFace.current = false
      // setTimeout(()=>{
      //   timeFace.current = true
      // },1000)
      if (facesDetected == 0) {
        setErroText({ menssagem: 'Nenhum rosto detectado', seta: null });
        timePositivoFace.current = 0
        return
      };
      if (facesDetected > 1) {
        setErroText({ menssagem: '2 ou mais rostos foram detectados', seta: null });
        timePositivoFace.current = 0

        return
      };

      if (facesDetected == 1) {
        if (boundingBox[0].width < 0.44) {
          setErroText({ menssagem: 'Aproxime seu rosto', seta: null });
          timePositivoFace.current = 0

          return
        }
        if (boundingBox[0].width > 0.64) {
          setErroText({ menssagem: 'Afaste seu rosto', seta: null });
          timePositivoFace.current = 0

          return
        };
        if (boundingBox[0].xCenter > 0.28) {
          setErroText({ menssagem: 'Alinhe seu rosto', seta: 'left' });
          timePositivoFace.current = 0
          return
        };
        if (boundingBox[0].xCenter < 0.15) {
          setErroText({ menssagem: 'Alinhe seu rosto', seta: 'right' });
          timePositivoFace.current = 0

          return
        };
        if (boundingBox[0].yCenter < 0.30) {
          setErroText({ menssagem: 'Alinhe seu rosto', seta: 'up' });
          timePositivoFace.current = 0

          return
        };
        if (boundingBox[0].yCenter > 0.38) {
          setErroText({ menssagem: 'Alinhe seu rosto', seta: 'down' });
          timePositivoFace.current = 0

          return
        };

        timePositivoFace.current = timePositivoFace.current + 1
        setErroText(false)
      }
    }
  }, [facesDetected, boundingBox[0]])


  function stopStreamedVideo() {
    webcamRef.current.stream.getTracks().forEach(track => track.stop());


    // const stream = webcamRef.current.stream;
    // const tracks = stream.getTracks();

    // tracks.forEach((track) => {
    //   track.stop();
    // });

    // webcamRef.current = null;
  }




  return (
    <div style={{
      display: 'flex',
      height: '85vh',
      // width: 'calc(100vw-10px)',
      width: '90vw',
      backgroundColor: 'black',
      justifyContent: 'center'
    }}>
      <div style={{
        position: "absolute",
        backgroundColor: "black",
        top: 0,
        bottom: 0,
        maxHeight: "100vh",
        maxWidth: 500,
      }}>
        {reconehcimentoFacial ?
          <>
            <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '90vh' }}>
              <div
                style={{
                  display: "flex",
                  backgroundImage: `url(${reconehcimentoFacial})`,
                  backgroundSize: 'cover',
                  backgroundPosition: "center center",
                  width: '280px',
                  height: '280px',
                  borderRadius: '200px'
                }}
              />


            </div>
            <div style={{ position: 'absolute', top: 0, left: 0, right: 0, width: '100vw', height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
              <div>

                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: animation,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice'
                    }
                  }}
                  height={'535px'}
                  width={'535px'}
                />
              </div>
            </div>

          </>
          :
          <>
            <Webcam
              ref={webcamRef}
              forceScreenshotSourceSize
              audio={false}
              height={'100%'}
              screenshotFormat="image/jpeg"
              width={'100%'}
              videoConstraints={videoConstraints}
              mirrored
            />
            <div className="overlay-container">
              {svgIcon(!erroText)}
            </div>

            {erroText && erroText.seta && erroText.seta == 'right' ? <div style={{ top: '48%', left: '65%', transform: 'rotate(0deg)', position: 'absolute', color: '#ff0000', fontSize: '90px' }} className="material-symbols-outlined">chevron_right</div> : <></>}
            {erroText && erroText.seta && erroText.seta == 'left' ? <div style={{ top: '48%', left: '10%', transform: 'rotate(180deg)', position: 'absolute', color: '#ff0000', fontSize: '90px' }} className="material-symbols-outlined">chevron_right</div> : <></>}
            {erroText && erroText.seta && erroText.seta == 'up' ? <div style={{ top: '31%', left: '38%', transform: 'rotate(270deg)', position: 'absolute', color: '#ff0000', fontSize: '90px' }} className="material-symbols-outlined">chevron_right</div> : <></>}
            {erroText && erroText.seta && erroText.seta == 'down' ? <div style={{ top: '62%', left: '36%', transform: 'rotate(90deg)', position: 'absolute', color: '#ff0000', fontSize: '90px' }} className="material-symbols-outlined">chevron_right</div> : <></>}
          </>
        }

      </div>

      {/* {erroText && <div style={{ top: '13%', position: 'absolute' }}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animation,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
          }}
          height={'530px'}
          width={'530px'}
        />
      </div>} */}


      <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', width: '100%', marginTop: 60, }}>

        <div
          style={{
            color: 'white',
            fontSize: 20,
            zIndex: 1000,
            fontSize: 16,
            textAlign: "center",
            width: '100%',
            lineHeight: 1.5
          }}
        >
          Posicione seu rosto dentro da marcação.
          <br /><br />
        </div>
        {erroText && erroText.menssagem ?
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              zIndex: 1000,
              width: '100%',
            }}
          >
            <div
              style={{
                maxWidth: 260,
                backgroundColor: "#dc3545",
                borderRadius: 8,
                padding: 2,
                color: 'white',
                fontSize: 16,
                lineHeight: 1.5,
                textAlign: "center",
                paddingInline: 32,
                textDecoration: 'underline'
              }}
            >
              {erroText.menssagem}
            </div>
          </div>
          :
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              zIndex: 1000,
              width: '100%',
            }}
          >
            <div
              style={{
                maxWidth: 260,
                backgroundColor: "#28A745",
                borderRadius: 8,
                padding: 2,
                color: 'white',
                fontSize: 16,
                lineHeight: 1.5,
                textAlign: "center",
                paddingInline: 32
              }}
            >
              {reconehcimentoFacial ? 'Verificando foto' : 'Mantenha sua cabeça reta'}
            </div>
          </div>
        }


      </div>


      <div className='buttons2' >
        {erroText && erroText.menssagem ?

          <></>
          :
          <Button
            variant="contained"
            size="large"
            sx={buttonStyles}
            onClick={() => tirarFoto()}
          // disabled={isDisabled}
          >
            Tirar Foto
          </Button>
        }
        {/* <button className='camod'></button> */}
        {/* <button className="capture" onClick={() => tirarFoto(facesDetected)}></button> */}
        {/* <button className='close' onClick={() => fecharCamera()}><AiOutlineClose /></button> */}
      </div>

    </div>

  );
};

export default WebcamDemo;
// tirarFoto