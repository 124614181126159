import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";

import "./QrCode.css";
import Lottie from "react-lottie";
import animationData from "../components/animations/qrload.json";
import animationDataQR from "../components/animations/qr.json";
import Preload from "./PreLoad";
import Success from "../components/alerta/success";
import Erro from "../components/alerta/erro";
import DetalheConvite from "./DetalheConvite";
import axios from "axios";
import QrCodeReader from "../components/qr-code-reader";

const PreLoad = (props) => {
  const route = useLocation();

  const [dadosConvite, setDadosConvite] = useState(null);
  const [listButton, setListButton] = useState([]);

  const dadosConviteRef = useRef(null);
  const listButtonRef = useRef([]);

  const isOnQR = useRef(true);

  const [toggle, setToggle] = useState("qrcode");

  useEffect(() => {
    if (route.search == "") {
      window.location.href = `${window.location.href}?1`;
    } else {
      const info = window.localStorage.getItem("@info");
      if (info) {
        const dados = JSON.parse(info);
        listButtonRef.current = dados.listButton;
        dadosConviteRef.current = dados.conviteDados;
        setDadosConvite(dados.conviteDados);
        setListButton(dados.listButton);
      }
      window.localStorage.removeItem("@info");
    }
    // console.log(window.location.href)
  }, []);

  //fixing bug that listButton misses timing when reading qrCode
  useEffect(() => {
    listButtonRef.current = listButton;
  }, [listButton]);

  useEffect(() => {
    dadosConviteRef.current = dadosConvite;
  }, [dadosConvite]);

  const onQrCode = ({ data }) => {
    if (isOnQR.current) {
      isOnQR.current = false;
      const button = listButtonRef.current.find(
        (b) => b.qr == data.split("#")[0]
      );
      if (button) {
        acioinarAcesso(
          { qr_code: data, id_trigger: button.id_trigger },
          toggle
        );
      }
      setTimeout(() => {
        isOnQR.current = true;
      }, 3000);
    }
  };

  const acioinarAcesso = async (d) => {
    try {
      setToggle("preload");

      const res = await axios.post(
        "https://fastpass-br.gear-tecnorise.com:4001/public/invitation/access/trigger",
        {
          id_invitation: dadosConviteRef.current.id_invitation,
          id_unit: dadosConviteRef.current.id_unity,
          type_acess: "qrcode",
          ...d,
        }
      );

      if (res.data.status) {
        setToggle("alert_success");
        setTimeout(() => {
          setToggle("qrcode");
        }, 2000);
      } else {
        setToggle("alert_erro");
        setTimeout(() => {
          setToggle("qrcode");
        }, 2000);
      }

      // return res.data.resultado
    } catch (e) {
      setToggle("alert_erro");
      setTimeout(() => {
        setToggle("qrcode");
      }, 2000);

      // console.log({ e })
    }
  };

  console.log(dadosConviteRef.current);

  return (
    <>
      {toggle === "qrcode" && (
        <div className="main">
          <QrCodeReader onScanSuccess={(value) => onQrCode(value)} />

          {/* <div
            style={{
              position: "absolute",
              top: "10px",
              width: "300px",
              height: "300px",
              overflow: "hidden",
            }}
            className="qrimage"
          >
            <QrReader
              onResult={qrcodeResult}
              showViewFinder={true}
              style={{ width: "100%", borderRadius: "10px" }}
              constraints={{
                facingMode: "environment",
              }}
              onResult={(result, error) => {}}
              onScan={(result, error) => {
                if (result) onQrCode(result);
              }}
              onError={(result, error) => {
                console.log({ result, error });
              }}
            />
          </div> */}

          <div style={{ marginTop: 10 }}>Escaneie o QR Code</div>

          <div className="qrinfo">
            <div style={{ margin: "15px 10px 64px" }}>
              <p style={{ margin: "20px", marginTop: "40px" }}>
                INSTRUÇÃO DE USO
              </p>
              <div className="instrucao">
                <div className="loading">
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: animationData,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    height={"150px"}
                    width={"150px"}
                  />
                </div>
                <div className="info">
                  No condomínio, localize o QR Code e aponte a câmera para ele
                </div>
              </div>
            </div>

            <DetalheConvite dadosConvite={dadosConviteRef.current} />
          </div>
        </div>
      )}

      {toggle === "preload" && <Preload />}

      {toggle === "alert_success" && <Success />}

      {toggle === "alert_erro" && (
        <Erro>
          {/* <div className='realizado'>
                            <span>Recarregue a página</span>
                        </div> */}
        </Erro>
      )}
    </>
  );
};
export default PreLoad;
