import { Container, Stack,  Typography } from "@mui/material";
import PreRegisterFormContainer from "./components/pre-register-container";
import { MdLockOutline } from "react-icons/md";

export default function PreRegister() {
  return (
    <Container fixed>
      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" spacing={0.5} mb={2}>
          <MdLockOutline />
          <Typography variant="overline" display="block" fontSize="0.70rem">
            ambiente seguro
          </Typography>
        </Stack>
        <PreRegisterFormContainer />
      </Stack>
    </Container>
  );
}
