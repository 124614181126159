import React, { useState, useRef } from "react";
import Webcam from "react-webcam";
import { CameraOptions, useFaceDetection } from "react-use-face-detection";
import FaceDetection from "@mediapipe/face_detection";
import { Camera } from "@mediapipe/camera_utils";
// import { AiOutlineClose, AiTwotoneWallet } from 'react-icons/ai'
import axios from "axios";
import { useEffect } from "react";
import Lottie from "react-lottie";
import animation from "../components/animations/loadFace.json";

//https://stackblitz.com/edit/js-4tzynd?file=index.js

const videoConstraints = {
  width: 480,
  height: 480,
  facesMode: "user",
  frameRate: 10,
};

const { innerWidth, innerHeight } = window;

const svgIcon = (status) => (
  <>
    <svg
      className="svg"
      viewBox="0 0 210 300"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <mask id="overlay-mask" x="0" y="0" width="100%" height="100%">
          <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
          {/* <circle cx="50%" cy="50%" r="80" w="100" /> */}
          <circle cx="50%" cy="50%" r="80" w="100" />

          {/* <path
            d="M149.8,399.69h-10.46c-75.39,0-136.5-61.11-136.5-136.5V118.97c0-64.14,52-116.13,116.13-116.13h51.2
            c64.14,0,116.13,52,116.13,116.13v144.22C286.3,338.57,225.19,399.69,149.8,399.69z"
            fill="#000"
            x="0"
            y="0"
            w="100"
            transform="scale(0.5)"
        /> */}
        </mask>
      </defs>
      <rect x="0" y="0" width="100%" height="100%" mask="url(#overlay-mask)" />
      <g
        style={{
          stroke: "#28A745",
          strokeWidth: status == false ? 0 : 2,
        }}
        fill="none"
      >
        <circle cx="50%" cy="50%" r="80" w="100" />
      </g>
    </svg>
  </>
);

{
  /* <circle cx="50%" cy="50%" r="80" w="100" stroke="#28A745" stroke-width="2" fill="#000"  /> */
}

const WebcamDemo = (
  {
    // finalizarFoto,
    // fecharCamera,
    // fotoEncontrada,
    // dadosConvite,
  }
) => {
  const [erroText, setErroText] = useState({
    menssagem: "Nenhum rosto detectado",
    seta: null,
  });
  const [reconehcimentoFacial, setReconehcimentoFacial] = useState(false);
  const [webCamVisivel, setWebCamVisivel] = useState(true);

  const timeFace = useRef(true);
  const timePositivoFace = useRef(0);
  const cam = useRef();
  const [toggle, setToggle] = useState("");
  const buttonClick = useRef(true);
  const [image, setImage] = useState("");
  const [form, setForm] = useState({});
  const [userJaCadastrado, setUserJaCadastrado] = useState("");
  const dadosConvite = {};
  const fotoEncontrada = ({ nome, id, fotoBase64, cpf }) => {
    setForm((prev) => ({ ...prev, nome, cpf }));
    setUserJaCadastrado(id);
    setToggle("newcadaster");
    if (fotoBase64) setImage(fotoBase64);
  };

  const finalizarFoto = async ({ proxTela, fotoBase64 }) => {
    setToggle(proxTela);
    if (fotoBase64) setImage(fotoBase64);
  };

  const { webcamRef, boundingBox, isLoading, detected, facesDetected } =
    useFaceDetection({
      faceDetectionOptions: {
        model: "short",
      },
      faceDetection: new FaceDetection.FaceDetection({
        locateFile: (file) =>
          `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
      }),

      camera: ({ mediaSrc, onFrame, width, height }) => {
        return new Camera(mediaSrc, {
          onFrame,
          width: 480,
          height: 480,
        });
      },
    });

  const wait = (ms) =>
    new Promise((resolve) => {
      setTimeout(() => resolve(), ms);
    });

  const tirarFoto = async (facesDetected) => {
    if (buttonClick.current) {
      buttonClick.current = false;

      try {
        const foto = webcamRef.current.getScreenshot();
        setReconehcimentoFacial(foto);
        // stopStreamedVideo()

        const url = dadosConvite.id_visitor
          ? "https://fastpass-br.gear-tecnorise.com:4001/faceDetect/visitor/identify"
          : "https://fastpass-br.gear-tecnorise.com:4001/faceDetect/visitor";

        const body = {
          imagem: foto.replace("data:image/jpeg;base64,", ""),
        };
        if (dadosConvite.id_visitor) body.id = dadosConvite.id_visitor;

        const res = await axios.post(url, body);
        await wait(1000);
        if (res.data && res.data.status) {
          fotoEncontrada({
            nome: res.data.resultado.name,
            id: res.data.resultado.id,
            cpf: res.data.resultado.cpf,
            fotoBase64: foto,
          });
        } else {
          finalizarFoto({
            proxTela: "verificarCPF",
            fotoBase64: foto,
          });
        }
      } catch (e) {
        //console.log(e)
        await wait(1000);
        setErroText({
          menssagem: "Erro ao identificar a foto, tente novamente.",
          seta: null,
        });
        setReconehcimentoFacial(null);
        buttonClick.current = true;
        timeFace.current = true;
        timePositivoFace.current = 0;
        //return console.log("Erro ao verificar a foto, tente novamente.");
      } finally {
        // setTimeout(() => {
        //   buttonClick.current = true
        // }, 1000 / 5)
        // setReconehcimentoFacial(false)
      }
    }
  };

  useEffect(() => {
    if (timePositivoFace.current == 5) {
      timePositivoFace.current = 0;
      if ((buttonClick.current = true)) {
        tirarFoto();
        timeFace.current = false;
        buttonClick.current = false;
      }
      // timeFace.current = false
    }
    if (timeFace.current) {
      // timeFace.current = false
      // setTimeout(()=>{
      //   timeFace.current = true
      // },1000)
      if (facesDetected == 0) {
        setErroText({ menssagem: "Nenhum rosto detectado", seta: null });
        timePositivoFace.current = 0;
        return;
      }
      if (facesDetected > 1) {
        setErroText({
          menssagem: "2 ou mais rostos foram detectados",
          seta: null,
        });
        timePositivoFace.current = 0;

        return;
      }

      if (facesDetected == 1) {
        if (boundingBox[0].width < 0.44) {
          setErroText({ menssagem: "Aproxime seu rosto", seta: null });
          timePositivoFace.current = 0;

          return;
        }
        if (boundingBox[0].width > 0.64) {
          setErroText({ menssagem: "Afaste seu rosto", seta: null });
          timePositivoFace.current = 0;

          return;
        }
        if (boundingBox[0].xCenter > 0.28) {
          setErroText({ menssagem: "Alinhe seu rosto", seta: "left" });
          timePositivoFace.current = 0;
          return;
        }
        if (boundingBox[0].xCenter < 0.15) {
          setErroText({ menssagem: "Alinhe seu rosto", seta: "right" });
          timePositivoFace.current = 0;

          return;
        }
        if (boundingBox[0].yCenter < 0.3) {
          setErroText({ menssagem: "Alinhe seu rosto", seta: "up" });
          timePositivoFace.current = 0;

          return;
        }
        if (boundingBox[0].yCenter > 0.38) {
          setErroText({ menssagem: "Alinhe seu rosto", seta: "down" });
          timePositivoFace.current = 0;

          return;
        }

        timePositivoFace.current = timePositivoFace.current + 1;
        setErroText(false);
      }
    }
  }, [facesDetected, boundingBox[0]]);

  console.log(image);
  function stopStreamedVideo() {
    webcamRef.current.stream.getTracks().forEach((track) => track.stop());

    // const stream = webcamRef.current.stream;
    // const tracks = stream.getTracks();

    // tracks.forEach((track) => {
    //   track.stop();
    // });

    // webcamRef.current = null;
  }

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        backgroundColor: "black",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          position: "absolute",
          backgroundColor: "black",
          top: 60,
          bottom: 0,
          maxHeight: "90vh",
          maxWidth: 500,
        }}
      >
        {reconehcimentoFacial ? (
          <>
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100vw",
                height: "90vh",
              }}
            >
              <div
                style={{
                  display: "flex",
                  backgroundImage: `url(${reconehcimentoFacial})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  width: "280px",
                  height: "280px",
                  borderRadius: "200px",
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                width: "100vw",
                height: "90vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: animation,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  height={"535px"}
                  width={"535px"}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Webcam
              ref={webcamRef}
              forceScreenshotSourceSize
              audio={false}
              height={"100%"}
              screenshotFormat="image/jpeg"
              width={"100%"}
              videoConstraints={videoConstraints}
              mirrored
            />
            <div className="overlay-container">{svgIcon(!erroText)}</div>
            {erroText && erroText.seta && erroText.seta == "right" ? (
              <div
                style={{
                  top: "48%",
                  left: "65%",
                  transform: "rotate(0deg)",
                  position: "absolute",
                  color: "#ff0000",
                  fontSize: "90px",
                }}
                className="material-symbols-outlined"
              >
                chevron_right
              </div>
            ) : (
              <></>
            )}
            {erroText && erroText.seta && erroText.seta == "left" ? (
              <div
                style={{
                  top: "48%",
                  left: "10%",
                  transform: "rotate(180deg)",
                  position: "absolute",
                  color: "#ff0000",
                  fontSize: "90px",
                }}
                className="material-symbols-outlined"
              >
                chevron_right
              </div>
            ) : (
              <></>
            )}
            {erroText && erroText.seta && erroText.seta == "up" ? (
              <div
                style={{
                  top: "31%",
                  left: "38%",
                  transform: "rotate(270deg)",
                  position: "absolute",
                  color: "#ff0000",
                  fontSize: "90px",
                }}
                className="material-symbols-outlined"
              >
                chevron_right
              </div>
            ) : (
              <></>
            )}
            {erroText && erroText.seta && erroText.seta == "down" ? (
              <div
                style={{
                  top: "62%",
                  left: "36%",
                  transform: "rotate(90deg)",
                  position: "absolute",
                  color: "#ff0000",
                  fontSize: "90px",
                }}
                className="material-symbols-outlined"
              >
                chevron_right
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>

      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginTop: 60,
        }}
      >
        <div
          style={{
            color: "white",
            fontSize: 20,
            zIndex: 1000,
            fontSize: 16,
            textAlign: "center",
            width: "100%",
            lineHeight: 1.5,
          }}
        >
          {dadosConvite.name ? (
            <>
              {dadosConvite.name.toUpperCase()}
              <br />
              É VOCÊ MESMO(A)?
              <br />
            </>
          ) : (
            <></>
          )}
          Posicione seu rosto dentro da marcação.
          <br />
          <br />
        </div>
        {erroText && erroText.menssagem ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              zIndex: 1000,
              width: "100%",
            }}
          >
            <div
              style={{
                maxWidth: 260,
                backgroundColor: "#dc3545",
                borderRadius: 8,
                padding: 2,
                color: "white",
                fontSize: 16,
                lineHeight: 1.5,
                textAlign: "center",
                paddingInline: 32,
                textDecoration: "underline",
              }}
            >
              {erroText.menssagem}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              zIndex: 1000,
              width: "100%",
            }}
          >
            <div
              style={{
                maxWidth: 260,
                backgroundColor: "#28A745",
                borderRadius: 8,
                padding: 2,
                color: "white",
                fontSize: 16,
                lineHeight: 1.5,
                textAlign: "center",
                paddingInline: 32,
              }}
            >
              {reconehcimentoFacial
                ? "Verificando foto"
                : "Mantenha sua cabeça reta"}
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          zIndex: 1000,
          width: "100%",
          color: "#d1d1d1",
          padding: "50px 0px",
          fontSize: 75,
        }}
        className="material-symbols-outlined"
      >
        cancel
      </div>
    </div>
  );
};

export default WebcamDemo;
