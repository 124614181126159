import { createContext, useCallback, useContext, useMemo, useReducer } from "react";

const defaultValue = {
  unitSelected: null,
}

export const UnitSelectedContext = createContext(defaultValue);

const reducerUnitSelected = (state, action) => {
  switch (action.type) {
    case 'SET_UNIT_SELECTED':
      return {
        ...state,
        unitSelected: action.payload
      };
    default:
      return state;
  }
};

export const UnitSelectedProvider = (({ children }) => {
  const [{ unitSelected }, dispatch] = useReducer(
    reducerUnitSelected,
    defaultValue,
  );

  const setUnitSelected = useCallback((data) => {
    dispatch({
      type: 'SET_UNIT_SELECTED',
      payload: data,
    });
  }, [dispatch]);

  const value = useMemo(() => ({
    unitSelected,
    setUnitSelected,
  }), [unitSelected, setUnitSelected]);

  return (
    <UnitSelectedContext.Provider value={value}>
      {children}
    </UnitSelectedContext.Provider>
  )
})

/**
 * Returns the value of the UnitSelectedContext using the useContext hook.
 *
 * @return {{
 *  unitSelected: { id: string; name: string },
 *  setUnitSelected: (data: { id: string; name: string }) => void
 *  }} The value of the UnitSelectedContext.
 * @example
 * const { unitSelected, setUnitSelected } = useCondominio();
 */
export const useUnitSelected = () => {
  return useContext(UnitSelectedContext);
}

