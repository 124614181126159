import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState, useDeferredValue, useMemo } from "react";
import { MdSearch } from 'react-icons/md';
import PerfectScrollbar from 'react-perfect-scrollbar';
import httpClient from "../../../http-client";
import { useCondominio } from "../contexts/condominio-context";
import { useSteps } from "../contexts/steps-context";
import { useUnitSelected } from "../contexts/unit-selected-context";

export default function UnitSelect() {
  const { nomeDoCondominio, urlLogo, setCondominio } = useCondominio();
  const { nextStep } = useSteps();
  const { setUnitSelected } = useUnitSelected();
  const [units, setUnits] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const deferredFilterValue = useDeferredValue(filterValue);
  const urlLogoFallback = useMemo(() => urlLogo || 'https://tecnorise-marcas-empresas.s3-sa-east-1.amazonaws.com/tecnorise.svg', [urlLogo]);

  const onClick = useCallback((unit) => () => {
    nextStep();
    setUnitSelected(unit)
  }, [nextStep, setUnitSelected]);

  const renderRow = useCallback((unit) => {
    return (
      <Button
        key={unit.id}
        onClick={onClick(unit)}
        variant="outlined"
        size="large"
        sx={{ paddingY: '1rem', }}
      >
        {unit.name}
      </Button>
    );
  }, [onClick]);

  const filteredOptions = useMemo(() => units.filter((option) =>
    option.name?.toLowerCase().includes(deferredFilterValue.toLowerCase())
  ), [deferredFilterValue, units]);

  useEffect(() => {
    async function getUnits() {
      const IDCondominio = window.location.href.split("?")[1];
      setIsLoading(true);

      const response = await httpClient.get(
        `/unity/${IDCondominio}`
      );

      if (response && response.data && response.data.status && response.data.result) {
        const unidades = response.data.result.units.map((item) => ({
          ...item,
          name: item.name.replace("null", "").toUpperCase(),
        }));

        setUnits(unidades);
        setCondominio({
          nomeDoCondominio: response.data.result.name,
          urlLogo: response.data.result.logo
        });
      }
      setIsLoading(false);
    }
    getUnits();
  }, [setCondominio]);

  return (
    <>
      <Stack alignItems="center" spacing={2} sx={{ width: '100%' }}>
        <Grid item xs={8}>
          <Typography variant="h6" color="GrayText" fontWeight="400">
            FICHA DE PRÉ-CADASTRO
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <img
            src={urlLogoFallback}
            alt="Logo Tecnorise"
            style={{ width: 200, height: 'auto' }}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1" color="GrayText" textAlign="center">
            Seja bem-vindo(a) ao <strong>{nomeDoCondominio}</strong>. Escolha um unidade?
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ width: '100%' }}>
          <TextField
            placeholder="Pesquise rápido a unidade"
            variant="standard"
            value={filterValue}
            fullWidth
            onChange={(e) => setFilterValue(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MdSearch size={20} />
                </InputAdornment>
              )
            }}
            sx={{
              minWidth: 360,
            }}
          />
        </Grid>

        {isLoading ? (
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Skeleton variant="rounded" height={60} />
            <Skeleton variant="rounded" height={60} />
            <Skeleton variant="rounded" height={60} />
            <Skeleton variant="rounded" height={60} />
            <Skeleton variant="rounded" height={60} />
            <Skeleton variant="rounded" height={60} />
            <Skeleton variant="rounded" height={60} />
            <Skeleton variant="rounded" height={60} />
          </Box>
        ) : null}

        {filteredOptions.length > 0 ? (
          <PerfectScrollbar
            style={{
              width: '100%',
              height: '360px',
              overflow: 'hidden',
            }}
          >
            <Stack
              spacing={1}
              sx={{
                width: '100%',
                paddingBottom: '2rem',
                height: '360px',
              }}
            >
              {filteredOptions.map(renderRow)}
            </Stack>
          </PerfectScrollbar>
        ) : null}

        {!isLoading && deferredFilterValue.length > 0 && units.length > 0 && filteredOptions.length === 0 ? (
          <Typography variant="body1" color="GrayText">
            Nenhuma unidade encontrada
          </Typography>
        ) : null}

      </Stack>
    </>
  )
}
