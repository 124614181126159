import React, { useEffect, useState, useRef, useCallback } from "react";
import Input from "../components/form/Input";
import Footer from "../components/Footer";
import Dica from "../assets/rostoRegra.png";
import Switch from "react-switch";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Checked from "../assets/checked.png";
import axios from "axios";
import WebcamComponent from "./WebcamComponent";
import styles from "./PreCadastro.module.css";
const PreCadastro = (props) => {
  const [toggle, setToggle] = useState("unidades");

  const [morador, setMorador] = useState([]);
  const [form, setForm] = useState({});
  const [tel, setTel] = useState("");

  const [toggleServices, setToggleServices] = useState(false);
  const [toggleCar, setToggleCar] = useState(false);
  const [termo, setTermo] = useState(false);

  const [image, setImage] = useState();

  const [nomeDoCondominio, setNomeDoCondominio] = useState("");
  const [urlLogoEmpresa, setUrlLogoEmpresa] = useState("");
  const [unidadesFilter, setUnidadesFilter] = useState([]);
  const [unidadesFull, setUnidadesFull] = useState([]);
  const [textFilter, setTextFilter] = useState("");
  const [selectUnidade, setSelectUnidade] = useState();
  const [erroTextCPF, setErroTextCPF] = useState("");
  const [formErros, setFormErros] = useState({});
  const [loading, setLoading] = useState(false);
  const [userJaCadastrado, setUserJaCadastrado] = useState(false);

  useEffect(() => {
    if (toggle === "cadastro") setForm({});
  }, [toggle]);

  useEffect(() => {
    const IDCondominio = window.location.href.split("?")[1];
    let tentativas = 0;
    console.log({ IDCondominio });
    async function listarUnidades(IDCondominio) {
      setLoading(true);
      tentativas = tentativas + 1;
      const url =
        "https://fastpass-br.gear-tecnorise.com:4001/public/unity/" +
        IDCondominio;
      try {
        const res = await axios.get(url);
        if (res && res.data && res.data.status && res.data.result) {
          const unidades = res.data.result.units.map((item) => ({
            ...item,
            name: item.name.replace("null", "").toUpperCase(),
          }));
          setUnidadesFilter(unidades);
          setUnidadesFull(unidades);
          setNomeDoCondominio(res.data.result.name);
          setUrlLogoEmpresa(res.data.result.logo);
        } else {
          if (tentativas <= 3) {
            listarUnidades(IDCondominio);
          } else {
            setToggle("erroCarregamentoDaURL");
          }
        }
      } catch (e) {
        if (tentativas <= 3) {
          console.log(e);
          listarUnidades(IDCondominio);
        } else {
          console.log(e);
          setToggle("erroCarregamentoDaURL");
        }
      } finally {
        setLoading(false);
      }
    }

    listarUnidades(IDCondominio);
  }, []);

  useEffect(() => {
    setUnidadesFilter(
      unidadesFull.filter((item) =>
        item.name
          .replace(/\s/g, "")
          .includes(textFilter.toUpperCase().replace(/\s/g, ""))
      )
    );
  }, [textFilter]);

  const handlePage = (unidade) => {
    setSelectUnidade(unidade);
    setToggle("condinfo");
  };

  //webcam
  const finalizarFoto = async ({ proxTela, fotoBase64 }) => {
    setToggle(proxTela);
    if (fotoBase64) setImage(fotoBase64);
  };

  const fecharCamera = () => {
    setToggle("condinfo");
  };

  const changeForm = (campo, valor) => {
    setFormErros((prevForm) => ({ ...prevForm, [campo]: undefined }));
    setForm((prevForm) => ({ ...prevForm, [campo]: valor }));
  };

  const saveData = async (e) => {
    e.preventDefault();
    let erros = {};

    if (!termo) erros.termo = true;
    if (!form.nome || form.nome.length <= 3) erros.nome = true;
    if (!tel || tel.length < 8) erros.tel = true;

    if (toggleServices) {
      if (!form.empresa || form.empresa.length <= 2) erros.empresa = true;
      if (!form.funcao || form.funcao.length <= 2) erros.funcao = true;
    }

    if (toggleCar) {
      if (!form.marca || form.marca.length <= 2) erros.marca = true;
      if (!form.modelo || form.modelo.length <= 2) erros.modelo = true;
      if (!form.placa || form.placa.length <= 2) erros.placa = true;
    }

    if (Object.keys(erros).length > 0) {
      setFormErros(erros);
      return;
    }

    const newMorador = {
      id_unit: selectUnidade.id,
      name: form.nome,
      base64_photo: image.replace("data:image/jpeg;base64,", ""),
    };

    if (tel && tel.slice(0, 2) == "55") {
      newMorador.cell_number = tel.slice(2);
    } else {
      newMorador.cell_number = tel;
    }

    if (toggleServices) {
      newMorador.company = form.empresa;
      newMorador.occupation = form.funcao;
    }

    if (toggleCar) {
      newMorador.vehicle = {
        vehicle_type: form.tipoVeiculo ? String(form.tipoVeiculo) : "0",
        vehicle_brand: form.marca,
        model: form.modelo,
        license_plate: form.placa,
        color: "",
      };
    }

    try {
      setLoading(true);
      if (userJaCadastrado) {
        newMorador.id = userJaCadastrado;
        const res = await axios.put(
          "https://fastpass-br.gear-tecnorise.com:4001/public/visitor",
          newMorador
        );
        if (res.data.status) {
          setToggle("realizado");
        } else {
          //console.log("erro");
        }
      } else {
        newMorador.cpf = form.cpf;
        const res = await axios.post(
          "https://fastpass-br.gear-tecnorise.com:4001/public/visitor",
          newMorador
        );
        if (res.data.status) {
          setToggle("realizado");
        } else {
          //console.log("erro");
        }
      }
    } catch (e) {
      //console.log(e)
    } finally {
      setLoading(false);
    }

    setMorador((prevMorador) => [...prevMorador, newMorador]);
    setToggle("realizado");
  };

  const changeSwitch = () => {
    if (!termo === true) {
      changeForm("termos", "aceitos");
    }
    setTermo((prevState) => !prevState);
  };

  async function validarDocumento() {
    const consultarCPF = async (cpf, id_unit) => {
      try {
        //setLoading(true);
        const res = await axios.post(
          "https://fastpass-br.gear-tecnorise.com:4001/public/visitor/cpf",
          {
            cpf: cpf,
            id_unit,
          }
        );

        if (res.data && res.data.status) {
          setForm((prev) => ({ ...prev, nome: res.data.resultado.name }));
          setTel(
            res.data.resultado.cell_number.length === 11
              ? "55" + res.data.resultado.cell_number
              : res.data.resultado.cell_number
          );
          setUserJaCadastrado(res.data.resultado.id);
          return true;
        } else {
          //setUserJaCadastrado(false);
          // setForm({})

          return false;
        }
      } catch (e) {
        //setUserJaCadastrado(false);
        return false;
      } finally {
        //setLoading(false);
      }
    };

    if (form.cpf) {
      const resConsultaCPF = await consultarCPF(form.cpf, selectUnidade.id);
      if (resConsultaCPF) {
        setToggle("newcadaster");
      } else {
        setToggle("newcadaster");
      }
    } else {
      setErroTextCPF("Informe um Documento válido.");
    }
  }

  // const functionsCPF = {
  //     validar: async () => {

  //         function TestaCPF(cpf) {
  //             if (!cpf) return false
  //             let Soma;
  //             let Resto;
  //             Soma = 0;

  //             let strCPF = cpf.replace('.', "").replace('.', "").replace('-', "");
  //             if (strCPF == "00000000000") return false;

  //             for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  //             Resto = (Soma * 10) % 11;

  //             if ((Resto == 10) || (Resto == 11)) Resto = 0;
  //             if (Resto != parseInt(strCPF.substring(9, 10))) return false;

  //             Soma = 0;
  //             for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  //             Resto = (Soma * 10) % 11;

  //             if ((Resto == 10) || (Resto == 11)) Resto = 0;
  //             if (Resto != parseInt(strCPF.substring(10, 11))) return false;
  //             return true;
  //         };

  //         const consultarCPF = async (cpf, id_unit) => {
  //             try {
  //                 setLoading(true);
  //                 const res = await axios.post('https://fastpass-br.gear-tecnorise.com:4001/public/visitor/cpf', {
  //                     cpf: cpf,
  //                     id_unit
  //                 })

  //                 if (res.data && res.data.status) {
  //                     setForm(prev => ({ ...prev, nome: res.data.resultado.name, }))
  //                     setTel(res.data.resultado.cell_number.length === 11 ? '55' + res.data.resultado.cell_number : res.data.resultado.cell_number)
  //                     setUserJaCadastrado(res.data.resultado.id);
  //                     return true;
  //                 } else {
  //                     setUserJaCadastrado(false);
  //                     return false;
  //                 };

  //             } catch (e) {
  //                 setUserJaCadastrado(false);
  //                 return false;
  //             } finally {
  //                 setLoading(false);
  //             };
  //         };

  //         const resTesteCPF = TestaCPF(form.cpf);
  //         if (resTesteCPF) {
  //             const resConsultaCPF = await consultarCPF(form.cpf, selectUnidade.id);

  //             if (resConsultaCPF) {
  //                 setToggle('newcadaster');
  //             } else {
  //                 setToggle('newcadaster');
  //             };
  //         } else {
  //             setErroTextCPF('Informe um CPF válido.');
  //         };
  //     },
  //     mask: cpf => {
  //         return cpf
  //             .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
  //             .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
  //             .replace(/(\d{3})(\d)/, '$1.$2')
  //             .replace(/(\d{3})(\d{1,2})/, '$1-$2')
  //             .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  //     },
  // };

  const fotoEncontrada = ({ nome, id, fotoBase64, cpf }) => {
    setForm((prev) => ({ ...prev, nome, cpf }));
    setUserJaCadastrado(id);
    setToggle("newcadaster");
    if (fotoBase64) setImage(fotoBase64);
    //setTel(res.data.resultado.cell_number.length === 11 ? '55' + res.data.=resultado.cell_number : res.data.resultado.cell_number)
  };

  const titleCase = (str) => `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`;

  console.log(morador);

 

  return (
    <div style={{ background: "#d1d1d1", padding: "0px 20px" }}>
      <div style={{ background: "#fff", paddingBottom: 1 }}>
        <div
          style={{ display: "flex", alignItems: "flex-end", padding: "10px" }}
        >
          <div
            className="material-symbols-outlined"
            style={{
              color: "#888",
              fontSize: "14px",
              marginRight: "3px",
            }}
          >
            https
          </div>
          <div style={{ fontSize: 9, color: "#888" }}>AMBIENTE SEGURO</div>
        </div>
        {loading && (
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}

        {toggle === "unidades" && (
          <div
            style={{
              marginBottom: 100,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <header className="header">
              <div className={styles.title}>FICHA DE PRÉ-CADASTRO</div>
              <img src={urlLogoEmpresa} alt="logo" style={{ marginTop: 10 }} />
            </header>
            <div className={styles.nameCondominium}>
              Seja bem-vindo(a) ao <strong>{nomeDoCondominio}</strong>. Qual
              unidade você quer visitar?
            </div>

            <div className="input-data" style={{ width: 300, marginTop: 10 }}>
              <label>Pesquise rápido a unidade</label>
              <input
                value={textFilter}
                onChange={(e) => setTextFilter(e.target.value)}
              />
            </div>

            {unidadesFilter.map((unidade) => (
              <button
                className={styles.linkbutton}
                key={unidade.id}
                onClick={() => handlePage(unidade)}
              >
                {unidade.name}
              </button>
            ))}
            <Footer />
          </div>
        )}

        {toggle === "condinfo" && (
          <div className={styles.cond}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: "0px 35px",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  textTransform: "uppercase",
                  fontSize: 20,
                  margin: 20,
                  color: "#828282",
                }}
              >
                {nomeDoCondominio}
              </div>
              <div style={{ color: "#828282" }}>Você escolheu a unidade:</div>
              <div
                style={{
                  color: "#317bff",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: 30,
                  margin: 30,
                }}
              >
                {selectUnidade.name}
              </div>
            </div>

            <div>
              <div style={{ margin: "0 35px", color: "#828282" }}>
                Para prosseguir, é necessário fazer o seu cadastro. Vamos
                precisar coletar a sua foto para identificação, pelo que você
                tem ciência de que a sua biometria será utilizada para
                finalidade especifica de acesso {nomeDoCondominio}.
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <button
                  onClick={() => setToggle("unidades")}
                  style={{
                    color: "rgb(164, 164, 164)",
                    border: "1px solid rgb(164, 164, 164)",
                  }}
                  className="button"
                >
                  Voltar
                </button>
                <button
                  className="button"
                  // onClick={() => setToggle("realizado")}
                  onClick={() => setToggle("cadastro")}
                >
                  Avançar
                </button>
              </div>
            </div>
            <div className="dicas">
              <p>Na hora da foto, veja as dicas:</p>
              <img src={Dica} alt="dica" style={{ marginTop: 20 }} />
            </div>

            <Footer />
          </div>
        )}

        {toggle === "cadastro" && (
          <WebcamComponent
            finalizarFoto={finalizarFoto}
            fecharCamera={fecharCamera}
            fotoEncontrada={fotoEncontrada}
            dadosConvite={{}}
          />
        )}

        {toggle === "erro" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              textAlign: "center",
            }}
          >
            Opss... Você precisa fazer o reconhecimento facial
          </div>
        )}

        {toggle === "erroCarregamentoDaURL" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              textAlign: "center",
              marginBottom: 100,
            }}
          >
            Erro ao carregar informações. Por favor, tente novamente.
          </div>
        )}

        {toggle === "verificarCPF" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              className="cardFoto"
              style={{
                display: "flex",
                marginTop: 20,
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                marginTop: 50,
                marginBottom: 40,
              }}
            />

            <div className="input-data">
              <label>{"Digite seu Documento"}</label>
              <input
                value={form.cpf || ""}
                disabled={toggle === "verificarCPF" ? false : true}
                onChange={(e) => {
                  setErroTextCPF("");
                  changeForm("cpf", e.target.value);
                }}
              />
              {erroTextCPF.length === 0 ? (
                <div
                  style={{
                    marginTop: 4,
                    fontSize: 12,
                    color: "lightgray",
                  }}
                >
                  Campo obrigatório
                </div>
              ) : (
                <div
                  style={{
                    color: "red",
                    fontSize: 12,
                    marginTop: 6,
                  }}
                >
                  {erroTextCPF}
                </div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                width: 360,
                justifyContent: "space-between",
              }}
            >
              <button
                style={{
                  color: "rgb(164, 164, 164)",
                  border: "1px solid rgb(164, 164, 164)",
                }}
                className="button"
                onClick={() => setToggle("cadastro")}
              >
                Tirar outra foto
              </button>

              <button className="button" onClick={validarDocumento}>
                Avançar
              </button>
            </div>

            <Footer />
          </div>
        )}

        {toggle === "newcadaster" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              className="cardFoto"
              style={{
                display: "flex",
                marginTop: 20,
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
              }}
            />

            <div
              style={{
                marginTop: 20,
              }}
            ></div>

            <Input
              type="text"
              text="Nome e Sobrenome"
              value={form.nome}
              onChange={(e) => changeForm("nome", e.target.value)}
              style={{ marginTop: 10 }}
              required={true}
              erro={formErros.nome}
              erroText="Digite seu nome e sobrenome"
            />
            <Input
              type="text"
              text="Documento"
              value={form.cpf}
              onChange={(e) => changeForm("cpf", e.target.value)}
              disabled={true}
            />

            <div
              className="input-data"
              style={{
                margin: 0,
              }}
            >
              <label>{"Contato"}</label>
            </div>

            <PhoneInput
              className="phoneInput"
              country="br"
              value={tel}
              onChange={(phone) => setTel(phone)}
              inputStyle={{
                width: "100%",
                border: "none",
                borderBottom: "1px solid #9f9f9f",
                borderRadius: 0,
                fontSize: "larger",
              }}
            />
            {formErros && formErros.tel ? (
              <div
                className="input-data"
                style={{
                  color: "red",
                  fontSize: 12,
                  marginTop: 6,
                }}
              >
                Digite um número válido
              </div>
            ) : (
              <div
                className="input-data"
                style={{
                  marginTop: 6,
                  fontSize: 12,
                  color: "lightgray",
                }}
              >
                Campo obrigatório
              </div>
            )}

            <div className="switch">
              <label>É um prestador de serviços?</label>
              <Switch
                width={50}
                onChange={() => setToggleServices(!toggleServices)}
                checked={toggleServices}
                uncheckedIcon={false}
                checkedIcon={true}
              />
            </div>

            {toggleServices && (
              <>
                <Input
                  type="text"
                  text="Empresa"
                  value={form.empresa}
                  onChange={(e) => changeForm("empresa", e.target.value)}
                  required={true}
                  erro={formErros.empresa}
                  erroText="Digite o nome da empresa"
                />
                <Input
                  type="text"
                  text="Função"
                  value={form.funcao}
                  onChange={(e) => changeForm("funcao", e.target.value)}
                  required={true}
                  erro={formErros.funcao}
                  erroText="Digite a sua função na empresa"
                />
              </>
            )}

            <div className="switch">
              <label>Vai entrar com um veículo ?</label>
              <Switch
                width={50}
                onChange={() => setToggleCar(!toggleCar)}
                checked={toggleCar}
                uncheckedIcon={false}
                checkedIcon={true}
              />
            </div>
            {toggleCar && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="input-data">
                  <label>Tipo</label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      width: "100%",
                      marginTop: 12,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: 22,
                      }}
                    >
                      <input
                        checked={form.tipoVeiculo === "0" || !form.tipoVeiculo}
                        type="checkbox"
                        name="moto"
                        value="0"
                        style={{ marginRight: 8 }}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setForm((prev) => ({ ...prev, tipoVeiculo: "0" }));
                          } else {
                            setForm((prev) => ({ ...prev, tipoVeiculo: "1" }));
                          }
                        }}
                      />
                      Moto
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: 30,
                      }}
                    >
                      <input
                        checked={form.tipoVeiculo === "1"}
                        type="checkbox"
                        name="carro"
                        value="1"
                        style={{ marginRight: 8 }}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setForm((prev) => ({ ...prev, tipoVeiculo: "1" }));
                          } else {
                            setForm((prev) => ({ ...prev, tipoVeiculo: "0" }));
                          }
                        }}
                      />
                      Carro
                    </div>
                  </div>
                </div>

                <Input
                  type="text"
                  text="Marca"
                  value={form.marca}
                  onChange={(e) => changeForm("marca", e.target.value)}
                  required={true}
                  erro={formErros.marca}
                  erroText="Digite a marca"
                />

                <Input
                  type="text"
                  text="Modelo"
                  value={form.modelo}
                  onChange={(e) => changeForm("modelo", e.target.value)}
                  required={true}
                  erro={formErros.modelo}
                  erroText="Digite o modelo"
                />

                <Input
                  type="text"
                  text="Placa"
                  value={form.placa}
                  onChange={(e) => changeForm("placa", e.target.value)}
                  required={true}
                  erro={formErros.placa}
                  erroText="Digite a placa"
                />
              </div>
            )}

            <div className="termo">
              <div className="termoHeader">
                <div className="termoInfo">
                  Tenho ciência que os dados pessoais <br /> acima serão
                  tratados para finalidade <br /> específica de acesso ao{" "}
                  {nomeDoCondominio}
                </div>
                <Switch
                  width={50}
                  uncheckedIcon={false}
                  checkedIcon={true}
                  checked={termo}
                  onChange={changeSwitch}
                />
              </div>

              <div
                style={{
                  color: "red",
                  fontSize: 12,
                  marginTop: 6,
                  width: "100%",
                }}
              >
                É necessário marcar esta opção para prosseguir
              </div>

              <button
                disabled={!termo}
                className="saveButton"
                onClick={saveData}
              >
                Salvar
              </button>
            </div>
            <Footer />
          </div>
        )}

        {toggle === "realizado" && (
          <div
            // className="realizado"
            style={{
              height: "100vh",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "40px 0px",
              }}
            >
              <img
                style={{ width: 130, height: 130 }}
                src={Checked}
                alt="sucesso"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "#424242",
                marginBottom: 30,
              }}
            >
              <div style={{ fontWeight: "bold" }}>
                {morador.map((nome) => nome.nome)}
              </div>
              <div>cadastro Realizado com sucesso!</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "#424242",
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              <div>Após a liberação por parte da unidade</div>
              <div
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                {titleCase(selectUnidade.name || "")}
              </div>
              <div>você receberá um link para acessar o condomínio.</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default PreCadastro;
