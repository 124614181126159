import Checked from '../assets/checked.png'
import './SemAccesso.css'


const Presencial = ({ dadosConvite}) => {

    return (

        <div className='presencialHeader'>
            <img src={Checked} alt="presencialFoto" />
            <div> <p style={{ color: '#000', textTransform: 'capitalize' }}>{dadosConvite.name && dadosConvite.name.includes(" ") && dadosConvite.name.split(' ')[0]},</p> o convite enviado por</div>
            <div> <p style={{ color: '#000', textTransform: 'capitalize' }}>{dadosConvite.name_created}</p> está confirmado</div>
        </div>

    )
}

export default Presencial