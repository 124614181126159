import { useForm, FormProvider, useFormContext } from "react-hook-form";
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

const schema = yup
  .object({
    facePicture: yup.string().required(),
    fullName: yup.string().required('Campo obrigatório'),
    cpf: yup.string().optional(),
    documentNumber: yup.number().positive().integer().optional(),
    phoneNumber: yup.string().optional(),
    email: yup.string().email().optional(),
    birthdate: yup.date().optional().nullable(),
    userType: yup.string().optional(),
    enterWithVehicle: yup.boolean(),
    agreeToTerms: yup.boolean().oneOf([true], 'Deve aceitar os termos para seguir').required('Deve aceitar os termos para seguir'),
    vehicle: yup.object().when('enterWithVehicle', {
      is: true,
      then: schema => schema.shape({
        brand: yup.string().required('Campo obrigatório'),
        model: yup.string().required('Campo obrigatório'),
        plate: yup.string().required('Campo obrigatório'),
        color: yup.string().required('Campo obrigatório'),
      }),
      otherwise: schema => schema.notRequired(),
    }),
  })
  .required()

const defaultValue = {
  facePicture: '',
  fullName: '',
  documentNumber: '',
  phoneNumber: '',
  email: '',
  birthdate: null,
  userType: 'locador',
  enterWithVehicle: false,
  agreeToTerms: false,
  vehicle: {
    brand: '',
    model: '',
    plate: '',
  }
}

export const PreRegisterFormProvider = ({ children }) => {
  const methods = useForm({
    defaultValue,
    resolver: yupResolver(schema),
  });

  return (
    <FormProvider {...methods}>
      {children}
    </FormProvider>
  )
}

export const usePreRegisterForm = () => {
  return useFormContext();
}
