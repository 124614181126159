import { createContext, useCallback, useContext, useMemo, useReducer } from "react";

const defaultValue = {
  nomeDoCondominio: "",
  urlLogo: "",
}

export const CondominioContext = createContext(defaultValue);

const reducerCondominio = (state, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const stateCondominio = {
  nomeDoCondominio: "",
  urlLogo: "",
};

export const CondominioProvider = (({ children }) => {
  const [{ nomeDoCondominio, urlLogo }, dispatch] = useReducer(
    reducerCondominio,
    stateCondominio,
  );

  const setCondominio = useCallback((data) => {
    dispatch({
      type: 'SET_DATA',
      payload: data
    });
  }, [dispatch]);

  const value = useMemo(() => ({
    nomeDoCondominio,
    urlLogo,
    setCondominio,
  }), [nomeDoCondominio, setCondominio, urlLogo]);

  return (
    <CondominioContext.Provider value={value}>
      {children}
    </CondominioContext.Provider>
  )
})

/**
 * Returns the value of the CondominioContext using the useContext hook.
 *
 * @return {{
 *  nomeDoCondominio: string,
 *  urlLogo: string
 *  setCondominio: (data: { nomeDoCondominio: string, urlLogo: string }) => void
 *  }} The value of the CondominioContext.
 * @example
 * const { nomeDoCondominio, urlLogo } = useCondominio();
 */
export const useCondominio = () => {
  return useContext(CondominioContext);
}

