import React, { useState, useEffect } from 'react';

// import CardMap from '../../components/CardMap';
import Lottie from 'react-lottie';
import animationLocalizacao from '../animations/localizacao.json';
import CalcCoordination from './calcCoordinates'
import './style.css'

export default function GearDashboard({ dados, onGps }) {

  const [distancia_do_local, setdistancia_do_local] = useState(undefined)

  useEffect(() => {

      getLocation()
    
    const time = setInterval(() => {
      getLocation()
    }, 5000);

    return () => clearInterval(time);
  }, []);

  function getLocation() {

    const navigatorLocationOptions = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };

      navigator.geolocation.getCurrentPosition((position) => {
        const dst = calcLocation(position.coords.latitude, position.coords.longitude, dados.lat, dados.lng)
        setdistancia_do_local(dst)
        if (dst < dados.distance_gps) {
          onGps && onGps({lat:position.coords.latitude,lng:position.coords.longitude})
        }
      },(erro)=>{
        //  alert(erro)
      }, navigatorLocationOptions);

    
  
      // navigator.permissions.query({name:'geolocation'})
      //   .then((result) => {
      //     if (result.state === 'granted') {
      //       navigator.geolocation.getCurrentPosition(pos => {
      //          alert('Retrieved user location', pos);
 
      //       }, (error) => {
      //         alert('System/OS services disabled', error);
              
      //       }, navigatorLocationOptions);
  
      //     } else {
      //       alert(`Browser location services disabled ${result.state}`);
            
      //     }
      //   }, (error) => {
      //     alert('Browser permissions services unavailable', error);
      //   }
      // );
     

  }

  const calcLocation = (latitude, longitude, latCond, lngCond) => {
    const pontoAtual = {
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude)
    }

    const destinoAtual = {
      latitude: parseFloat(latCond),
      longitude: parseFloat(lngCond)
    }

    const distancia = parseInt(CalcCoordination(pontoAtual, destinoAtual) * 1000)
    return distancia
  }

  function varificaStringDistancia() {
    if (distancia_do_local) {
      if (distancia_do_local > 1000) {
        return `${distancia_do_local / 1000} KM`
      } else {
        return `${distancia_do_local} Metros`
      }
    }
    return ''
  }


  return (

    <div className="loadingGps" style={{backgroundColor: '#000',width:'100vw'}}>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: animationLocalizacao,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}

        height={'20%'}
        width={'20%'}
        style={{ margin: 0 }}
      />
      {distancia_do_local ?
      <>
          <span  style={{color:'#fff'}}>Você precisa está perto do local do convite</span>
          <span  style={{color:'#fff'}}>distância atual é de {varificaStringDistancia()}</span> 
      </>
      :

      <>
        <span  style={{color:'#fff'}}>Verificando localização...</span>
        {/* <span className='card-information-header'>distância atual é de {varificaStringDistancia()}</span>  */}
      </>


    }

    </div>


  );


}
